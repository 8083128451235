<template>
  <div class="minimal-layout">
    <div id="viewport">
      <OHeaderMinimal />
      <slot />
      <OFooter />
    </div>
  </div>
</template>

<script>
import config from 'config';
import { isServer } from '@vue-storefront/core/helpers';
import OHeaderMinimal from '~/theme/components/organisms/o-header-minimal';
import OFooter from '~/theme/components/organisms/o-footer';
import Head from '~/theme/head';

export default {
  components: {
    OHeaderMinimal,
    OFooter
  },
  metaInfo: Head
}
</script>
