import { getBundleOptionPrice, getBundleOptionsValues } from '@vue-storefront/core/modules/catalog/helpers/bundleOptions';
import { scaleBySpecialPrice, scalePriceByQuantity } from 'theme/helpers/price/utils';
import { usesTierPrices, calculateTierPrice } from 'theme/helpers/price/tierPrices';
import get from 'lodash-es/get'
import round from 'lodash-es/round'

import config from 'config'
function isBundleProductFixedPriced (product) {
  return product?.price_type === 1 // price set to non-dynamic in magento admin
}

export function calculateBundleOptionsPrice (product) {
  if (!product.bundle_options || product.bundle_options.length === 0) return null
  const isStaticallyPriced = isBundleProductFixedPriced(product)
  const allBundleOptions = product.bundle_options || []
  const selectedBundleOptions = Object.values(get(product, 'product_option.extension_attributes.bundle_options', {}))
  if (!selectedBundleOptions.length) return product.price_range.min_price
  const bundleOptionsWithQuantity = getBundleOptionsValues(selectedBundleOptions as any[], allBundleOptions)
  const bundlePrice = getBundleOptionPrice(bundleOptionsWithQuantity, isStaticallyPriced)

  return bundlePrice.price
}

function calculateBundleOptionsSpecialPrice (product) {
  if (!product.bundle_options || product.bundle_options.length === 0) return null
  const isStaticallyPriced = isBundleProductFixedPriced(product)
  const allBundleOptions = product.bundle_options || []
  const selectedBundleOptions = Object.values(get(product, 'product_option.extension_attributes.bundle_options', {}))
  if (!selectedBundleOptions.length) return product.price_range.min_price
  const childrenProductsSpecialPrices = getBundleOptionsValues(selectedBundleOptions as any[], allBundleOptions).map(productLink => {
    const childProduct = productLink.product
    if (isStaticallyPriced) {
      // Hack requested by Protein. We should round prices, but only for dynamically priced bundles
      const ROUNDING_PRECISION_FOR_BUNDLE_CHILDREN = 2
      const specialPrice = scaleBySpecialPrice(productLink.price, product.special_price)
      const roundedSpecialPrice = round(specialPrice, ROUNDING_PRECISION_FOR_BUNDLE_CHILDREN)
      return scalePriceByQuantity(roundedSpecialPrice, childProduct !== undefined && childProduct.qty ? childProduct.qty : 1)
    }
    let price = childProduct !== undefined && childProduct.qty ? childProduct.final_price : productLink.price

    const specialPrice = scaleBySpecialPrice(price, product.special_price)
    return scalePriceByQuantity(specialPrice, childProduct !== undefined && childProduct.qty ? childProduct.qty : 1)
  })
  return childrenProductsSpecialPrices.reduce((sumOfPrices, price) => sumOfPrices + price, 0)
}

export function calculateBundleProductPricePerUnit (product) {
  const simpleBundleRegularPrice = product.regular_price || 0
  const regular = product.bundle_original_price || calculateBundleOptionsPrice(product) + simpleBundleRegularPrice
  let special = calculateBundleOptionsSpecialPrice(product) + scaleBySpecialPrice(simpleBundleRegularPrice, product.special_price)
  const isStaticallyPriced = isBundleProductFixedPriced(product)
  if (!isStaticallyPriced) {
    // Hack requested by Protein. We should round prices, but only for dynamically priced bundles
    special = round(special, config.i18n.dynamicBundleProductsFractionDigits)
  }
  return { regular, special, final: special }
}
