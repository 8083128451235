<template>
  <div class="o-notification">
    <SfNotification
      v-for="notification in notifications"
      :key="notification.id"
      class="notification"
      :visible="true"
      :type="getType(notification)"
      :message="decodedMessage(notification)"
    >
      <template #action>
        <SfButton
          v-for="action in getActions(notification)"
          :key="action.label"
          class="sf-button--pure sf-notification__action"
          @click="executeAction(action.action, notification.id)"
        >
          {{ action.label }}
        </SfButton>
      </template>
      <template #close>
        <SfIcon
          class="sf-notification__close"
          icon="cross"
          color="white"
          @click="removeNotification(notification.id)"
        />
      </template>
    </SfNotification>
  </div>
</template>

<script>
import { Notification } from '@vue-storefront/core/modules/notification/components/Notification';
import SfButton from '@storefront-ui/vue/src/components/atoms/SfButton/SfButton.vue';
import SfIcon from '@storefront-ui/vue/src/components/atoms/SfIcon/SfIcon.vue';
import SfNotification from '@storefront-ui/vue/src/components/molecules/SfNotification/SfNotification.vue';

import config from 'config';
export default {
  name: 'ONotification',
  mixins: [Notification],
  components: { SfNotification, SfIcon, SfButton },
  computed: {

  },
  methods: {
    decodedMessage (notification) {
      this.$bus.$emit('error-notification', { error: notification.message })
      if (!notification) {
        return this.$t('Internal validation error. Please check if all required fields are filled in. Please contact us on {email}', { email: config.mailer.contactAddress })
      }
      try {
        return this.$options.filters.htmlDecode(notification.message)
      } catch (err) {
        console.error(notification.message)
        return notification.message
      }
    },
    getType ({ type }) {
      return ['secondary', 'info', 'success', 'warning', 'danger'].includes(type) ? type : 'danger';
    },
    getActions ({ action1, action2 }) {
      return [action1, action2].filter(Boolean);
    },
    executeAction (action, id) {
      if (typeof action === 'function') {
        action();
      }
      this.$store.dispatch('notification/removeNotificationById', id);
    },
    removeNotification (id) {
      this.$store.dispatch('notification/removeNotificationById', id);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.o-notification {
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(100% - 2 * var(--spacer-sm));
  padding: 0 var( --spacer-sm);
  z-index: var( --notification-z-index);
  @include for-desktop {
    top: 100px;
    right: 5%;
    width: auto;
  }
}
.notification {
  --notification-close-top: auto;
  --notification-close-right: var(--spacer-sm);
  margin-top: var(--spacer-sm);
  width: 100%;
  .sf-notification__action {
    cursor: pointer;
    margin: 0.3rem 1rem 0 0;
  }
  @include for-desktop {
    max-width: 32rem;
    align-self: end;
    .sf-notification__close {
      position: relative;
      margin-left: var(--spacer-lg);
    }
  }
}
</style>
