<template>
  <div class="checkout-layout">
    <div id="viewport">
      <div class="o-header-checkout">
        <div class="w-25" />
        <ALogo />
        <div class="w-25" />
      </div>
      <slot />
    </div>
    <ONotification />
  </div>
</template>

<script>
import Head from '~/theme/head';
import ALogo from '~/theme/components/atoms/a-logo';
import ONotification from '~/theme/components/organisms/o-notification';

export default {
  components: {
    ALogo,
    ONotification
  },
  metaInfo: Head
}
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

#viewport {
  @include for-desktop {
    background: var(--c-light-variant);
  }
}

.o-header-checkout {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: var(--header-main-height);
  @include for-desktop {
    max-width: var(--container-width);
    padding: 0 var(--spacer-2xl);
    margin: 0 auto var(--spacer-xl);
  }
  .w-25 {
    width: 25%;
    padding: 0 var(--spacer-sm);
  }
}
</style>
