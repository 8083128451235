import config from 'config';

const Home = () =>
  import(/* webpackChunkName: "vsf-home" */ '~/theme/pages/Home');
const ErrorPage = () =>
  import(/* webpackChunkName: "vsf-error" */ '~/theme/pages/Error');
const Product = () =>
  import(/* webpackChunkName: "vsf-product" */ '~/theme/pages/Product');
const Category = () =>
  import(/* webpackChunkName: "vsf-category" */ '~/theme/pages/Category');
const Checkout = () =>
  import(/* webpackChunkName: "vsf-checkout" */ '~/theme/pages/Checkout');
const CheckoutConfirmation = () =>
  import(/* webpackChunkName: "vsf-checkout-confirmation" */ '~/theme/pages/CheckoutConfirmation');
const MyAccount = () =>
  import(/* webpackChunkName: "vsf-my-account" */ '~/theme/pages/MyAccount');
const Static = () =>
  import(/* webpackChunkName: "vsf-static" */ '~/theme/pages/Static');
const ReviewsListing = () =>
  import(/* webpackChunkName: "vsf-static" */ '~/theme/pages/ReviewsListing');
const Builder = () =>
  import(/* webpackChunkName: "vsf-static" */ '~/theme/pages/Builder');
const KlarnaConfirmation = () =>
  import(/* webpackChunkName: "vsf-checkout-confirmation" */ '~/theme/pages/KlarnaConfirmation');
const AddToCart = () =>
  import(/* webpackChunkName: "vsf-add-to-cart" */ '~/theme/pages/AddToCart');
const PaypalCheckout = () =>
  import(/* webpackChunkName: "vsf-paypal" */ '~/theme/pages/PaypalCheckout');
const Track = () =>
  import(/* webpackChunkName: "vsf-track" */ '~/theme/pages/Track');

let routes = [
  { name: 'home', path: '/', component: Home, alias: '/pwa.html' },
  { name: 'my-account', path: '/my-account', component: MyAccount },
  { name: 'checkout', path: '/checkout', component: Checkout, meta: { layout: 'checkout' } },
  { name: 'checkout-confirmation', path: '/checkout/confirmation', component: config.klarna.isKlarnaEnabled ? KlarnaConfirmation : CheckoutConfirmation },
  { name: 'paypal-checkout', path: '/paypal-checkout', component: PaypalCheckout, meta: { layout: 'checkout' } },
  { name: 'virtual-product', path: '/p/:parentSku/:slug', component: Product },
  { name: 'bundle-product', path: '/p/:parentSku/:slug', component: Product },
  { name: 'simple-product', path: '/p/:parentSku/:slug', component: Product },
  { name: 'downloadable-product', path: '/p/:parentSku/:slug', component: Product },
  { name: 'grouped-product', path: '/p/:parentSku/:slug', component: Product },
  { name: 'configurable-product', path: '/p/:parentSku/:slug/:childSku', component: Product },
  { name: 'product', path: '/p/:parentSku/:slug/:childSku', component: Product },
  { name: 'category', path: '/c/:slug', component: Category },
  { name: 'cms-page', path: '/i/:slug', component: Static },
  { name: 'page-not-found', path: '*', component: ErrorPage },
  { name: 'reviews-listing', path: '/reviews-listing', component: ReviewsListing },
  { name: 'pro-tin-pdp', path: '/protin-pdp', component: Builder },
  { name: 'addtocart', path: '/addtocart/:sku/qty/:qty', component: AddToCart },
  { name: 'track', path: '/track', component: Track },
  { name: 'error', path: '/error', component: ErrorPage, meta: { layout: 'minimal' } }
];

export default routes;
