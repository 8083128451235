<template>
  <router-link :to="localizedRoute('/')" :aria-label="$t('Protein')" :title="$t('Home Page')" class="a-logo main-logo">
    <SfImage
      class="main-logo-image"
      :src="image"
      alt="Protein"
    />
  </router-link>
</template>

<script>
import config from 'config';
import { currentStoreView } from '@vue-storefront/core/lib/multistore';
import get from 'lodash-es/get';
import SfImage from '@storefront-ui/vue/src/components/atoms/SfImage/SfImage.vue';

const storeView = currentStoreView();

export default {
  props: {
    image: {
      type: String,
      default: '/assets/1px.png'
    }
  },
  components: { SfImage },
  computed: {
    defaultTitle () {
      return get(storeView, 'seo.defaultTitle', config.seo.defaultTitle);
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.a-logo {
  position: relative;
  top: -2px;
  left: 5px;
  display: inline-flex;
  .main-logo-image {
    width: 94px;
    height: 25px;
    background-image: url(/assets/logo.svg);
    background-position: left top;
    img {
      opacity: 0;
    }
  }
}
</style>
<style lang="scss">
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
.a-logo {
  .main-logo-image {
    img {
      opacity: 0;
    }
  }
}
.checkout-layout {
  .a-logo {
    @include for-desktop {
      .main-logo-image {
        background-image: url(/assets/logo-checkout.svg);
      }
    }
  }
}
</style>
