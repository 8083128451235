import { formatCategoryLink, formatProductLink } from '@vue-storefront/core/modules/url/helpers';
import config from 'config'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'
import { productThumbnailPath, getThumbnailPath, isServer } from '@vue-storefront/core/helpers'
import { htmlDecode } from '@vue-storefront/core/filters'
import { router } from '@vue-storefront/core/app'

import { formatPrice, getProductPrice, countPercentage } from './price'
export * from './price'
export * from './order'
export * from './badges'
export function getPathForStaticPage (path: string) {
  const { storeCode } = currentStoreView()
  const isStoreCodeEquals = storeCode === config.defaultStoreCode

  return isStoreCodeEquals ? `/i${path}` : path
}

export function createSmoothscroll (from = 0, to = 0, speed = 15) {
  let currentDelta = from > to ? from - to : to - from
  let smoothscroll = () => {
    if (Math.abs(currentDelta) < speed) return
    currentDelta = currentDelta - currentDelta / speed
    window.requestAnimationFrame(smoothscroll);
    window.scrollTo(0, from > to ? to + currentDelta : to - currentDelta);
  }
  return smoothscroll()
}

export function checkWebpSupport (bannersToTransform, isWebpSupported) {
  // In SSR it is not easily known whether webp image is supported by web browser or not.
  // Empty string also cannot be used here, because empty string evaluates to url('')
  // and it is resolved as the base URL (the same as our Homepage), so as a consequence
  // Homepage was requested again.
  // To fix that case, dummy empty data URI is provided just to prevent any unnecessary
  // requests.
  // --- see https://github.com/DivanteLtd/vsf-capybara/issues/168
  const theSmallestDummyImage = 'data:,'
  return bannersToTransform.map(banner => Object.assign(
    {},
    banner,
    { image: isServer ? theSmallestDummyImage : isWebpSupported ? banner.image.webp : banner.image.fallback }
  ))
}

export function getTopLevelCategories (categoryList) {
  // Display only the root level (level = 1 => Default Category), categoriesDynamicPrefetchLevel = 2 by default
  const categoryLevel = config.entities.category.categoriesDynamicPrefetchLevel >= 0
    ? config.entities.category.categoriesDynamicPrefetchLevel
    : 2

  return categoryList.filter(
    category => category.level === categoryLevel && category.is_active && category.children_count > 0
  )
}

function handleListedProductPrice (product) {
  if (product.type_id === 'grouped') {
    return {
      regular: formatPrice(product.price_range?.min_price)
    }
  }
  return {
    ...getProductPrice(product, {}, true),
    final: product.final_price
  }
}

function calculatePercentageOff (product) {
  if (product.type_id !== 'simple') {
    return Math.floor(100 - product.final_price)
  }
  const productPrice = getProductPrice(product, {}, true)
  return countPercentage(productPrice.regular, product.final_price)
}

export function prepareListingProduct (product) {
  return {
    id: product.id,
    sku: product.sku,
    category: product.category,
    badges: product.badges,
    badgesSortOrder: product.badges_sort_order,
    title: htmlDecode(product.name),
    typeId: product.type_id,
    image: product?.product_images?.thumbnail || config.images.productPlaceholder,
    hover_image: product?.product_images?.hover,
    link: formatProductLink(product, currentStoreView().storeCode, true),
    price: handleListedProductPrice(product),
    percentageOff: calculatePercentageOff(product),
    finalPrice: product.final_price,
    rating: { max: 5, score: 5 },
    gtmCustomDimensions: product.gtmCustomDimensions,
    parentSku: product?.parentSku || product.sku,
    isInStock: product?.stock?.is_in_stock
  }
}

export function prepareCategoryMenuItem (category) {
  if (!category) return;

  return {
    id: category.id,
    name: category.name,
    link: formatCategoryLink(category),
    count: category.product_count || '',
    position: category.position,
    path: category.path
  };
}

export function accordion (instant = false) {
  if (isServer) return;
  let triggerAccordion = () => {
    let accordionItemAll = document.querySelectorAll('.toggle-question');
    if (accordionItemAll && accordionItemAll.length > 0) {
      accordionItemAll.forEach((item) => {
        item.parentNode.replaceChild(item.cloneNode(true), item);
      })
      accordionItemAll = document.querySelectorAll('.toggle-question');
      accordionItemAll.forEach((item) => {
        item.addEventListener('click', (e) => {
          if (item.classList[1] === undefined) {
            item.classList.add('active')
          } else {
            item.classList.remove('active')
          }
        })
      })
    }
  }
  setTimeout(triggerAccordion, (instant) ? 1 : 2000)
}

export function replaceAnchorTag (refs) {
  var links = refs.querySelectorAll('a')
  for (var i = 0; i < links.length; i++) {
    links[i].addEventListener('click', (event) => {
      var target = event.target
      while (target) {
        if (target instanceof HTMLAnchorElement) {
          var link = target.getAttribute('href')
          if (link.substr(0, 4) === 'http' || link.substr(0, 3) === 'tel' || link.substr(0, 6) === 'mailto') {
            window.location.href = link
          } else {
            router.push(target.getAttribute('href'))
          }
          break
        }
        target = target.parentNode
      }
      event.preventDefault()
    })
  }
}

export function setCookie (name, value, days) {
  var expires = '';
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    expires = '; expires=' + date.toUTCString();
  }
  document.cookie = name + '=' + (value || '') + expires + '; path=/';
}

export function getCookie (name) {
  var nameEQ = name + '=';
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

export function removeCookie (name) {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
}

export function approveCookie (name) {
  var midnight = new Date();
  midnight.setHours(24, 0, 0, 0); // Set the time to midnight
  var expires = '; expires=' + midnight.toUTCString();
  document.cookie = name + '=' + true + expires + '; path=/';
}

export function deleteAllCookies () {
  const cookies = document.cookie.split(';')
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i]
    const eqPos = cookie.indexOf('=')
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie
    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT'
  }
}
