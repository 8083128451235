import config from 'config';
import supportsWebP from 'supports-webp';
import dayjs from 'dayjs';
import localForage from 'localforage';
import getApiEndpointUrl from '@vue-storefront/core/helpers/getApiEndpointUrl';
import { isServer, processLocalizedURLAddress } from '@vue-storefront/core/helpers';
import rootStore from '@vue-storefront/core/store';
import { modalStore } from './modals';

interface Labels {
  labels: string[],
  productLabel: string
}

export const uiStore = {
  namespaced: true,
  modules: {
    modal: modalStore
  },
  state: {
    sidebar: false,
    searchpanel: false,
    sidebarSearchFocus: false,
    microcart: false,
    wishlist: false,
    languageSwitcher: false,
    productQuickSwitcher: false,
    checkoutSwitcher: false,
    overlay: false,
    loader: false,
    checkoutMode: false,
    openMyAccount: false,
    submenu: {
      depth: false,
      path: []
    },
    isWebpSupported: true,
    isReviewProductTab: false,
    isMobileMenu: false,
    deliveryDate: {
      desktop: {
        labels: [],
        productLabel: ''
      },
      mobile: {
        labels: [],
        productLabel: ''
      }
    },
    cookieDecline: false,
    dislikeSwitcher: false
  },
  mutations: {
    setSidebar (state, action) {
      state.sidebar = action === true;
      state.overlay = action === true;
    },
    setSearchpanel (state, action) {
      state.searchpanel = action === true;
      state.overlay = action === true;
    },
    setSidebarSearchFocus (state, action) {
      state.sidebarSearchFocus = action === true;
    },
    setMicrocart (state, action) {
      state.microcart = action === true;
      state.overlay = action === true;
    },
    setWishlist (state, action) {
      state.wishlist = action === true;
      state.overlay = action === true;
    },
    setLanguageSwitcher (state, action) {
      state.languageSwitcher = action === true;
      state.overlay = action === true;
    },
    setProductQuickSwitcher (state, action) {
      state.productQuickSwitcher = action === true;
      state.overlay = action === true;
    },
    setCheckoutSwitcher (state, action) {
      state.checkoutSwitcher = action === true;
      state.overlay = action === true;
    },
    setOverlay (state, action) {
      state.overlay = action === true;
    },
    setSubmenu (state, { id, depth }) {
      if (id) {
        state.submenu.path.push(id);
      } else if (state.submenu.path.length) {
        setTimeout(() => {
          state.submenu.path.pop();
        }, 300);
      }
      state.submenu.depth = state.submenu.depth > 0 && depth;
    },
    setLoader (state, action) {
      state.loader = action === true;
    },
    setWebpSupport (state, action) {
      state.isWebpSupported = action
    },
    setReviewProductTab (state, action) {
      state.isReviewProductTab = action
    },
    setDeliveryDate (state, deliveryDate) {
      state.deliveryDate = deliveryDate
    },
    setCookieDecline (state, action) {
      state.cookieDecline = action
    },
    setDislikeSwitcher (state, dislikeSwitcher) {
      state.dislikeSwitcher = dislikeSwitcher
    }
  },
  actions: {
    toggleSidebar ({ commit, state }, action) {
      const setTo = action === undefined ? !state.sidebar : action
      commit('setSearchpanel', false)
      commit('setSidebarSearchFocus', false)
      commit('setSidebar', setTo)
    },
    toggleMicrocart ({ commit, state }, action) {
      const setTo = action === undefined ? !state.microcart : action
      commit('setMicrocart', setTo)
    },
    toggleWishlist ({ commit, state }, action) {
      const setTo = action === undefined ? !state.wishlist : action
      commit('setWishlist', setTo)
    },
    toggleLanguageSwitcher ({ commit, state }, action) {
      const setTo = action === undefined ? !state.languageSwitcher : action
      commit('setLanguageSwitcher', setTo)

      /**
       * Force close the other items.
       */
      if (action === false) {
        commit('setSearchpanel', false)
        commit('setSidebar', false)
        commit('setMicrocart', false)
      }
    },
    toggleProductQuickSwitcher ({ commit, state }, action) {
      const setTo = action === undefined ? !state.productQuickSwitcher : action
      commit('setProductQuickSwitcher', setTo)

      /**
       * Force close the other items.
       */
      if (action === false) {
        commit('setSearchpanel', false)
        commit('setSidebar', false)
        commit('setMicrocart', false)
      }
    },
    toggleCheckoutSwitcher ({ commit, state }, action) {
      const setTo = action === undefined ? !state.productQuickSwitcher : action
      commit('setCheckoutSwitcher', setTo)
    },
    checkWebpSupport ({ commit }) {
      supportsWebP.then(isWebpSupported => {
        commit('setWebpSupport', isWebpSupported);
      })
    },
    async initiateDeliveryDateLabels ({ commit, dispatch }) {
      const labels = !isServer && await localForage.getItem('deliveryDate.labels')
      labels && commit('setDeliveryDate', labels)
      dispatch('fetchDeliveryDateLabels')
    },
    async fetchDeliveryDateLabels ({ commit }) {
      const storeView = rootStore.getters['getCurrentStoreView']
      const storeCode = storeView.storeCode || config.defaultStoreCode
      const deliveryDateEndpoint = processLocalizedURLAddress(getApiEndpointUrl(config.deliveryDate, 'delivery_date_endpoint'))

      const deliveryDateResponse = await fetch(deliveryDateEndpoint, { headers: {
        'Content-Type': 'application/json',
        'x-vs-store-code': storeCode
      } })
      const [deliveryDate, deliveryDateMobile] = (await deliveryDateResponse.json()).result

      const { desktop } = deliveryDate || {}
      const { mobile } = deliveryDateMobile || {}

      const dow = dayjs().day()
      const hour = dayjs().hour()
      const isHourInRange = _ => hour >= +_.hour_from && hour < +_.hour_to + 1
      const setLabels = (labels) => {
        const productLabel = labels?.third_label
        return {
          labels: [labels?.label, labels?.second_label, labels?.fourth_label],
          productLabel
        }
      }

      const todayLabels = (desktop || []).filter(_ => +_.day_of_week === dow)
      const todayLabelsMobile = (mobile || []).filter(_ => +_.day_of_week === dow)

      const deliveryDateLabels: { desktop?: Labels, mobile?: Labels } = {}
      deliveryDateLabels.desktop = setLabels(todayLabels.find(isHourInRange))
      deliveryDateLabels.mobile = setLabels(todayLabelsMobile.find(isHourInRange))
      !isServer && localForage.setItem('deliveryDate.labels', deliveryDateLabels)
      commit('setDeliveryDate', deliveryDateLabels)
    },
    toggleCookieDecline ({ commit, state }, action) {
      const setTo = action === undefined ? !state.cookieDecline : action
      commit('setCookieDecline', setTo)
      if (action === false) {
        commit('setSearchpanel', false)
        commit('setSidebar', false)
        commit('setMicrocart', false)
      }
    },
    toggleDislikeSwitcher ({ commit, state }, action) {
      const setTo = action === undefined ? !state.dislikeSwitcher : action
      commit('setDislikeSwitcher', setTo)
      if (action === false) {
        commit('setSearchpanel', false)
        commit('setSidebar', false)
        commit('setMicrocart', false)
      }
    }
  }
};
