<template>
  <div class="footer">
    <OFooterFaq v-if="!isCheckout()" />
    <div id="footer" v-html="setCurrentYear(cmsFooter)" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { isServer } from '@vue-storefront/core/helpers';
import OFooterFaq from '~/theme/components/organisms/o-footer-faq';

export default {
  name: 'OFooter',
  components: {
    OFooterFaq
  },
  computed: {
    ...mapGetters({
      cmsBlocks: 'cmsBlock/getCmsBlocks'
    }),
    cmsFooter () {
      return this.cmsBlocks.find(_ => _.identifier === 'footer_new')?.content || ''
    }
  },
  methods: {
    isCheckout () {
      return this.$route.path === '/checkout/confirmation';
    },
    setCurrentYear (html) {
      const currentYear = new Date().getFullYear()
      return html.replace('{CURRENT_YEAR}', currentYear)
    }
  },
  mounted () {
    if (isServer) return;

    this.$store.dispatch('cmsBlock/single', { value: 'footer' })
  },
  async asyncData ({ store }) {
    await store.dispatch('cmsBlock/single', { value: 'footer' });
  }
};
</script>

<style lang="scss">
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

#footer {
  background-color: var(--c-theme-color);
  a {
    color: var(--c-header-text-color);
  }
  picture {
    img {
      width: 100%;
      max-width: 100%;
    }
  }
  .footer-row {
    @include for-desktop {
      display: flex;
      justify-content: var(--footer-justify-content);
      align-items: center;
    }
    .footer-ogo {
      img {
        @include for-desktop {
          max-width: 170px
        }
        max-width: 140px
      }
    }
    .support, .footer-ogo {
      @include for-desktop {
        width: 27%;
      }
    }
    .disc {
      @include for-desktop {
         width: 48%
      }
    }
  }
}
</style>
<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
::v-deep .o-footer {
  @include for-desktop {
    max-width: var(--container-width);
    margin: 0 auto;
  }
  .sf-footer {
    --footer-width: auto;
    padding: 0 var(--spacer-sm) 30px;
    color: var(--c-header-text-color);
    &__container {
      width: 100%;
      margin: auto;
      display: flex;
      flex-wrap: wrap;
      input {
        display: none;
      }
      input:checked+label {
        background-color: var(--footer-column-title-background, var(--c-light));
      }
      input:checked~.sf-footer-column__content {
        height: auto;
        color: var(--menu-item-label-color, var(--c-dark-variant));
        @include for-desktop {
          overflow: auto;
        }
      }
      label {
        padding: var(--footer-column-title-padding, var(--spacer-base));
        text-transform: uppercase;
        display: block;
        width: 100%;
        box-sizing: border-box;
        cursor: pointer;
        font-family: var(--font-family--primary);
        @include for-desktop {
          cursor: default;
        }
        @include for-mobile {
          padding: 0 0 15px;
        }
      }
      /* New footer css */
      padding-top: var(--top-0);
      .sf-footer-column__content {
        background-color: transparent;
        height: auto;
        overflow: visible;
      }
      .logo-devider {
        width: 100%;
        position: relative;
        display: flex;
        justify-content: center;
        margin-bottom: 60px;
        z-index: 1;
        @include for-mobile {
          margin-top: 15px;
          margin-bottom: 45px;
        }
        &:after {
          content: "";
          position: absolute;
          left: 0;
          right: 0;
          height: 1px;
          background-color: #FFFFFF;
          top: 15px;
          z-index: -1;
        }
        img {
          display: block;
          background-color: var(--c-theme-color);
          padding: 0 10px;
        }
      }
      .sf-list {
        &__item {
          @include for-mobile {
            margin: 8px 0;
          }
        }
        .sf-menu-item__label {
          transition: 0.3s;
          color: inherit;
        }
      }
      .sf-footer-column {
        @include for-mobile {
          margin: 8px 0 40px 0;
          width: 50%;

          &:nth-child(2n) {
            padding-left: 15px;
          }
        }
      }
    }
    p {
      line-height: 160%;
    }
    &-column {
      --list-item-padding: 0 0 0 var(--spacer-sm);
      @include for-desktop {
        --list-item-padding: 0;
        width: var(--_footer-column-width, 20%);
      }
      &__content {
        width: 100%;
        margin: 0 auto;
        height: 0;
        color: rgba(0, 0, 0, 0);
        background-color: rgba(255, 255, 255, 0.2);
        overflow: hidden;
        @include for-desktop {
          overflow: auto;
          height: auto;
          color: var(--menu-item-label-color, var(--c-dark-variant));
        }
      }
      .sf-list__item {
        padding: 0;
      }
    }
  }

  .social-icons {
    display: var(--display-flex);
    align-items: center;
    padding: var(--spacer-2xs) var(--spacer-xs);
    padding: var(--spacer-xs) var(--spacer-sm) var(--spacer-xs);
    @include for-desktop {
      padding: var(--spacer-2xs) 0;
    }
    @include for-mobile {
      padding-left: 0;
    }
  }
  .social-banners {
    padding: var(--spacer-sm) var(--spacer-sm) var(--spacer-base);
    @include for-desktop {
      padding: var(--spacer-2xs) 0;
    }
  }
}

::v-deep .sf-bar {
  --bar-height: 40px;
  --bar-font-size: var(--font-size--sm);
  justify-content: center;
  .sf-link {
    --link-color: var(--c-white);
    --c-link-hover: var(--c-light);
  }
}
::v-deep .language-switcher {
  border-top: 1px solid var(--c-secondary-variant);
  background-color: transparent;
  @include for-mobile {
    --bar-height: 70px;
  };
  .sf-list {
    display: flex;
    --list-item-padding: 0 var(--spacer-xs);
  }
}
::v-deep .copyright {
  color: var(--c-white);
  background-color: var(--c-secondary-variant);
}
</style>
