<template>
  <div class="footer-toggle-wrap" v-html="cmsFooterFaq" />
</template>

<script>
import { mapGetters } from 'vuex';
import { accordion } from '~/theme/helpers';

export default {
  name: 'OFooterFaq',
  computed: {
    ...mapGetters({
      cmsBlocks: 'cmsBlock/getCmsBlocks'
    }),
    cmsFooterFaq () {
      let identifier = this.$route.path === '/checkout/confirmation' ? 'faq-order-success' : 'faq-above-footer'
      return this.cmsBlocks.find(_ => _.identifier === identifier)?.content || ''
    }
  },
  mounted () {
    accordion();
  }
};
</script>

<style lang="scss">
.footer {
  .footer-toggle-wrap {
    padding: 40px var(--spacer-sm) 0;
    max-width: var(--container-width);
    margin: 0 auto;
    h2 {
      margin: 0 0 30px;
      font-weight: var(--font-weight--medium);
      font-size: var(--font-size--3xl);
    }
    .toggle-question {
      font-size: 14px;
      line-height: 14px;
      color: rgba(0,0,0,.65);
      padding: 14px 14px 16px 43px;
      background-color: rgba(0,0,0,.045);
      transition: .5s ease all;
      text-align: center;
      font-weight: var(--font-weight--semibold);
      margin: 0;
      cursor: pointer;
      position: relative;
      &::before {
        content: "+";
        position: absolute;
        left: 13px;
        top: 13px;
        font-size: 24px;
        transition: 0.2s;
        background-color: #888;
        color: var(--c-white);
        width: 15px;
        height: 15px;
        line-height: 15px;
        border-radius: 15px;
        padding-left: 1px;
        padding-top: 1px;
      }
    }
    .toggle-answer {
      padding: 0 14px;
      transition: .5s ease all;
      max-height: 0;
      overflow: hidden;
      box-shadow: 0 1px 2px rgba(0,0,0,0.2);
      background-color: var(--c-white);
      text-align: center;
      color: var(--c-true-black);
      line-height: 25px;
      margin: 0 0 15px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .toggle-question.active {
      background-color: var(--c-theme-color);
      color: var(--c-white);
      &::before {
        content: "-";
        line-height: 12px;
        padding-top: 0;
        background-color: var(--c-white);
        color: var(--c-theme-color);
      }
    }
    .toggle-question.active + .toggle-answer {
      max-height: 2000px;
      padding-top: 15px;
      padding-bottom: 15px;
    }
  }
}

</style>
