import isNumber from 'lodash-es/isNumber'

export function getCheaperPrice (priceA, priceB) {
  if (!isNumber(priceA)) return priceB
  if (!isNumber(priceB)) return priceA
  return priceA > priceB ? priceB : priceA
}

export function scalePriceByQuantity (price, quantity) {
  if (!isNumber(quantity)) return price
  if (!isNumber(price)) return null
  return price * quantity
}

export function scaleBySpecialPrice (price, specialPrice) {
  const multiplier = isNumber(specialPrice) ? specialPrice : 100
  return Math.round(price * multiplier) / 100
}
