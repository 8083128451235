/* eslint-disable @typescript-eslint/no-use-before-define */
import { price } from '@vue-storefront/core/filters';
import isNumber from 'lodash-es/isNumber'
import config from 'config'
import { Logger } from '@vue-storefront/core/lib/logger'
import { scalePriceByQuantity } from 'theme/helpers/price/utils';
import { calculateBundleProductPricePerUnit } from 'theme/helpers/price/bundleProducts';
import { usesTierPrices, calculateTieredProductPrice } from 'theme/helpers/price/tierPrices';
import { calculatePriceWithCapybaraLogic } from 'theme/helpers/price/legacyCapybaraLogic';

function calculateFallbackProductPricePerUnit (product) {
  return {
    regular: product.regular_price || product.price,
    special: product.special_price,
    final: product.final_price
  }
}

export function formatPrice (value) {
  if (!isNumber(value)) return ''
  return value || value === 0 ? price(value) : ''
}

function handlePriceFormatting (price, formatIntoNumber) {
  if (formatIntoNumber) {
    return {
      regular: price.regular,
      special: price.special
    }
  }
  return {
    regular: formatPrice(price.regular),
    special: formatPrice(price.special),
    final: formatPrice(price.final)
  }
}

function calculateGiftPrice (product) {
  return {
    regular: product.regular_price,
    special: isNumber(product.giftPrice) ? product.giftPrice : product.special_price,
    final: product.final_price
  }
}

export function getProductPriceFromTotals (product) {
  if (!product.totals || !product.totals.options) {
    return {
      regular: '',
      special: ''
    }
  }

  const special = product.totals.row_total_incl_tax
  const regular = product.totals.row_total_incl_tax - product.totals.discount_amount

  return {
    regular: formatPrice(regular),
    special: formatPrice(special)
  }
}

export function getProductPrice (product, customOptions = {}, number = false, overrideQty = null) {
  // WARNING: Please add tests for ever new bugfix/feature you add as this function starts to be hard to manage
  // proteinse-vue-storefront/src/themes/protein/test/unit/price.spec.ts
  if (!product) {
    Logger.error(`invalid given product`, 'getProductPrice', product)()
    return {
      regular: '',
      special: ''
    }
  }
  const isBundleProduct = product.type_id === 'bundle'

  const quantity = overrideQty || product.qty

  if (product.isGift) {
    const giftPrice = calculateGiftPrice(product)
    return handlePriceFormatting(giftPrice, number)
  }

  if (usesTierPrices(product)) {
    const priceObject = calculateTieredProductPrice(product, quantity)
    return handlePriceFormatting(priceObject, number)
  }

  if (!config.i18n.acceptTaxesCalculatedOnApi) {
    const pricePerUnit = isBundleProduct ? calculateBundleProductPricePerUnit(product) : calculateFallbackProductPricePerUnit(product)
    const regularPrice = scalePriceByQuantity(pricePerUnit.regular, quantity)
    const specialPrice = scalePriceByQuantity(pricePerUnit.special, quantity)
    const finalPrice = scalePriceByQuantity(pricePerUnit.final, quantity)

    if (regularPrice === null && specialPrice === null && !product.price_range?.min_price) {
      Logger.error(`product sku:${product.sku} has wrong regular price`, 'getProductPrice', product)()
    }

    return handlePriceFormatting({
      regular: regularPrice,
      special: specialPrice,
      final: finalPrice
    }, number)
  }

  return calculatePriceWithCapybaraLogic(product, customOptions, number)
}

export function countPercentage (total, obtained) {
  return Math.floor((100 * (total - obtained)) / total)
}
