<template>
  <div class="default-layout">
    <MLoader />
    <div id="viewport">
      <OHeader @click="headClicked = true" />
      <OSidebar />
      <LazyHydrate :trigger-hydration="headClicked">
        <OMicrocart />
      </LazyHydrate>
      <LazyHydrate :trigger-hydration="headClicked">
        <OWishlist />
      </LazyHydrate>
      <OLanguageSwitcher />
      <OProductQuickViewSwitcher />
      <OCheckoutSwitcher />
      <slot />
      <OFooter />
      <OModal />
      <ONotification />
      <!-- <MCookieNotification v-if="!cookieEnabled" /> -->
      <MOfflineBadge />
    </div>
    <vue-progress-bar />
  </div>
</template>

<script>
import config from 'config';
import { isServer } from '@vue-storefront/core/helpers';
import { mapState, mapActions } from 'vuex'
import OModal from '~/theme/components/organisms/o-modal';
import MLoader from '~/theme/components/molecules/m-loader';
import ONotification from '~/theme/components/organisms/o-notification';
// import MCookieNotification from '~/theme/components/molecules/m-cookie-notification';
import MOfflineBadge from '~/theme/components/molecules/m-offline-badge';
import Head from '~/theme/head';
import LazyHydrate, { hydrateWhenVisible, hydrateWhenIdle, hydrateNever } from 'vue-lazy-hydration'
import { getCookie } from 'theme/helpers'

const OLanguageSwitcher = () => import(/* webpackChunkName: "vsf-o-language-switcher" */'~/theme/components/organisms/o-language-switcher');
const OHeader = () => import(/* webpackChunkName: "vsf-o-header" */'~/theme/components/organisms/o-header');
const OSidebar = () => import(/* webpackChunkName: "vsf-o-sidebar" */'~/theme/components/organisms/o-sidebar');
const OWishlist = () => import(/* webpackChunkName: "vsf-o-wishlist" */'~/theme/components/organisms/o-wishlist');
const OMicrocart = () => import(/* webpackChunkName: "vsf-o-microcart" */'~/theme/components/organisms/o-microcart');
const OFooter = () => import(/* webpackChunkName: "vsf-o-footer" */'~/theme/components/organisms/o-footer');
const OProductQuickViewSwitcher = () => import(/* webpackChunkName: "vsf-o-product-quick-view-switcher" */'~/theme/components/organisms/o-product-quick-view-switcher');
const OCheckoutSwitcher = () => import(/* webpackChunkName: "vsf-o-language-switcher" */'~/theme/components/organisms/o-checkout-switcher');

export default {
  components: {
    OHeader: hydrateWhenIdle(OHeader),
    OSidebar: OSidebar,
    OLanguageSwitcher: hydrateWhenVisible(OLanguageSwitcher),
    OProductQuickViewSwitcher: hydrateWhenVisible(OProductQuickViewSwitcher),
    OCheckoutSwitcher: hydrateWhenVisible(OCheckoutSwitcher),
    OMicrocart: hydrateNever(OMicrocart),
    OWishlist: hydrateNever(OWishlist),
    OFooter: OFooter,
    MLoader,
    ONotification,
    // MCookieNotification,
    MOfflineBadge,
    OModal,
    LazyHydrate
  },
  data () {
    return {
      quicklink: null,
      headClicked: false
    };
  },
  computed: {
    ...mapState({
      isMicrocartOpen: state => state.ui.microcart
    }),
    cookieEnabled () {
      return config.cookieFirst.enabled
    }
  },
  beforeMount () {
    // Progress bar on top of the page
    this.$router.beforeEach((to, from, next) => {
      this.$Progress.start();
      this.$Progress.increase(40);
      next();
    });
    this.$router.afterEach(() => {
      this.$Progress.finish();
    });
  },
  mounted () {
    this.languagePopup()
    if (isServer) return;
    // this.fetchMenuData(); // We need this as when we go to this layout from different one, menu is lost
    this.$store.dispatch('ui/checkWebpSupport');
    this.$store.dispatch('cmsBlock/list', {});
    if (config.trustbadge && config.trustbadge.tsid) {
      this.initTrustbadge(config.trustbadge.tsid)
    }
  },
  methods: {
    ...mapActions('trustbadge', {
      initTrustbadge: 'init'
    }),
    async languagePopup () {
      if (config.storeSwitchPopup && !getCookie('_language_popup')) {
        setTimeout(() => this.$store.dispatch('ui/toggleLanguageSwitcher'), 5000)
      }
    }
  },
  metaInfo: Head
};
</script>

<style>
.sf-product-card__title {
  /* autoprefixer: off */
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  box-orient: vertical;
  overflow: hidden;
/* will not be prefixed */
}
</style>
