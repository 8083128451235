import { setupMultistoreRoutes } from '@vue-storefront/core/lib/multistore';
import App from './App';
import routes from './router';
import Vue from 'vue';
import VueProgressBar from 'vue-progressbar';
import '@vue-storefront/core/lib/passive-listeners';
import { once } from '@vue-storefront/core/helpers';
import { module as cartModule } from './store/cart';
import { claimsStore } from '~/theme/store/claims';
import { uiStore } from '~/theme/store/ui';
import { StorageManager } from '@vue-storefront/core/lib/storage-manager';
import config from 'config'
import { isServer } from '@vue-storefront/core/helpers'

async function clearinvalidatedCache () {
  if (isServer) return;
  if (config.cart.alwaysAcceptServerItemsOnInitialLoad) {
    localStorage.removeItem('shop/cart/current-cart');
    localStorage.removeItem('shop/cart/current-cart-hash');
    localStorage.setItem('has-to-pull-cart-content', true);
  }

  const freshCacheVersion = config.localForage.cacheVersion + '';
  const versionOfCurrentCache = localStorage.getItem('cacheVersion');
  if (freshCacheVersion === versionOfCurrentCache) return;
  console.log('Cache outdated, prunning');
  const keysToRemove = Object.keys(localStorage).filter(key => !config.localForage.persistantCacheEntries.includes(key));
  keysToRemove.forEach(keyToRemove => {
    localStorage.removeItem(keyToRemove);
  });

  localStorage.setItem('has-to-pull-cart-content', true);
  localStorage.setItem('cacheVersion', freshCacheVersion);

  const indexDbDatabases = await indexedDB.databases();
  indexDbDatabases.forEach(db => {
    indexedDB.deleteDatabase((db.name));
  })
  if (navigator.serviceWorker) {
    const serviceWorkersRegistrations = await navigator.serviceWorker.getRegistrations();
    serviceWorkersRegistrations.forEach(serviceWorkerRegistration => {
      try {
        serviceWorkerRegistration.update();
      } catch (err) {
        console.error('Service worker update failed', err);
      }
    });
  }

  sessionStorage.clear();
  console.log('Cache prunned')
}

clearinvalidatedCache();

once('__VUE_EXTEND_DROPPOINT_VPB__', () => {
  Vue.use(VueProgressBar);
});

const themeEntry = App;
function initTheme (app, router, store, config) {
  store.registerModule('themeCart', cartModule);
  // Register theme routes for the current store. In a single store setup this will add routes exactly as they are in the router definition file '[theme]/router/index.js'
  // In a multistore setup, it depends on the config setting 'appendStoreCode' for the current store
  // - true = the store code will be added to the front of all routes, e.g. name: 'de-checkout', path: '/de/checkout'
  // - false = the store code will not be added. In this case you need to define custom routes for each of your stores
  // You can also define custom routes to use a different component, for example for German storeView checkout
  // To do so, exclude the desired storeView from the config.storeViews.mapStoreUrlsFor, set appendStoreCode = false, and map all the urls by your own like:
  // { name: 'de-checkout', path: '/checkout', component: CheckoutCustomized }
  // The 4th parameter is the route priority - a higher number will ensure the theme routes override any module routes. The default is 0.
  setupMultistoreRoutes(config, router, routes, 10);

  StorageManager.init('claims');
  store.registerModule('claims', claimsStore);
  store.registerModule('ui', uiStore);
}

export { themeEntry, initTheme };
