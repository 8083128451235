import config from 'config';

export default {
  title: config.seo.meta.title,
  titleTemplate: config.seo.meta.titleTemplate,
  htmlAttrs: {
    lang: config.seo.meta.lang
  },
  meta: [
    { charset: 'utf-8' },
    {
      vmid: 'description',
      name: 'description',
      content: config.seo.meta.description
    },
    {
      vmid: 'keywords',
      name: 'keywords',
      content: config.seo.meta.keywords
    },
    {
      name: 'viewport',
      content: 'width=device-width, initial-scale=1, minimal-ui'
    },
    { vmid: 'robots', name: 'robots', content: `${config.seo.meta.index}, ${config.seo.meta.follow}` },
    { name: 'mobile-web-app-capable', content: 'yes' },
    { name: 'theme-color', content: '#ffffff' },
    { name: 'apple-mobile-web-app-status-bar-style', content: '#97beab' }
  ],
  link: [
    { rel: 'preload', as: 'image', type: 'image/svg+xml', href: '/assets/logo.svg' },
    { rel: 'preload', as: 'image', type: 'image/svg+xml', href: '/assets/logo-checkout.svg' },
    {
      rel: 'preload',
      as: 'style',
      href: '/static/configurable-styles.css'
    },
    {
      rel: 'stylesheet',
      href: '/static/configurable-styles.css'
    },
    {
      rel: 'stylesheet',
      href: '/assets/css/animate.min.css'
    },
    {
      rel: 'icon',
      type: 'image/png',
      href: '/assets/favicon-32x32.png',
      sizes: '32x32'
    },
    {
      rel: 'icon',
      type: 'image/png',
      href: '/assets/favicon-16x16.png',
      sizes: '16x16'
    },
    { rel: 'apple-touch-icon', href: '/assets/apple-touch-icon.png' },
    {
      rel: 'apple-touch-startup-image',
      href: '/assets/apple_splash_2048.png',
      sizes: '2048x2732'
    },
    {
      rel: 'apple-touch-startup-image',
      href: '/assets/apple_splash_1668.png',
      sizes: '1668x2224'
    },
    {
      rel: 'apple-touch-startup-image',
      href: '/assets/apple_splash_1536.png',
      sizes: '1536x2048'
    },
    {
      rel: 'apple-touch-startup-image',
      href: '/assets/apple_splash_1125.png',
      sizes: '1125x2436'
    },
    {
      rel: 'apple-touch-startup-image',
      href: '/assets/apple_splash_1242.png',
      sizes: '1242x2208'
    },
    {
      rel: 'apple-touch-startup-image',
      href: '/assets/apple_splash_750.png',
      sizes: '750x1334'
    },
    {
      rel: 'apple-touch-startup-image',
      href: '/assets/apple_splash_640.png',
      sizes: '640x1136'
    },
    { rel: 'manifest', href: '/assets/manifest.json' },
    { rel: 'dns-prefetch', href: 'https://fonts.gstatic.com/' }
  ],
  script: [
    {
      src: 'https://cdn.jsdelivr.net/npm/pwacompat@2.0.17/pwacompat.min.js',
      async: true,
      crossorigin: 'anonymous'
    },
    {
      src: config.cookieFirst.enabled ? config.cookieFirst.snippetUrl : '',
      async: true,
      crossorigin: 'anonymous'
    }
  ]
};
