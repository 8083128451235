<template>
  <div id="app" :data-page="currentPage">
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>

<script>
import get from 'lodash-es/get'
import DefaultLayout from './layouts/Default'
import CheckoutLayout from './layouts/Checkout'
import MinimalLayout from './layouts/Minimal'
import { registerModule } from '@vue-storefront/core/lib/modules';
import { GoogleTagManagerModule } from 'src/modules/google-tag-manager'
import { Klaviyo } from 'src/modules/klaviyo'
import { getCookie, deleteAllCookies, setCookie } from './helpers'
import { isServer } from '@vue-storefront/core/helpers'
import { makeCookieClear } from 'config'

export default {
  components: {
    DefaultLayout,
    CheckoutLayout,
    MinimalLayout
  },
  computed: {
    currentPage () {
      return this.$route.name;
    },
    layout () {
      return `${get(this.$route, 'meta.layout', 'default')}-layout`
    }
  },
  mounted () {
    // const cookieData = JSON.parse(localStorage.getItem('shop/claims/cookiesAccepted'));
    // const acceptCookieStatus = getCookie('approved_cookies');
    // if (cookieData && cookieData.value && acceptCookieStatus) {
    //   registerModule(GoogleTagManagerModule);
    //   registerModule(Klaviyo);
    //   this.$bus.$emit('cookies-accepted');
    //   this.$bus.$emit('gtm-approved-cookies');
    // }
    registerModule(GoogleTagManagerModule);
    registerModule(Klaviyo);
    this.$bus.$emit('cookies-accepted');
    this.$bus.$emit('gtm-approved-cookies');

    if (this.$route.query.coupon) {
      localStorage.setItem('shop/cart/applyCartCoupon', this.$route.query.coupon);
    }
  },
  beforeMount () {
    if (!isServer && makeCookieClear.active) {
      const { siteCookieVar } = makeCookieClear;
      const hasCookie = getCookie(siteCookieVar);
      if (!hasCookie) {
        localStorage.clear();
        deleteAllCookies();
        caches?.keys().then((keyList) => Promise.all(keyList.map((key) => caches.delete(key))));
        setCookie(siteCookieVar, true, { expires: 30 });
      }
    }
  }
};
</script>

<style lang="scss" src="theme/css/main.scss"></style>
<style lang="scss">
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

html {
  scroll-behavior: smooth;
}

body {
  --overlay-z-index: 1;
  --sidebar-aside-z-index: 2;
  --bottom-navigation-height: 3.75rem;
  --bar-height: 3.125rem;
  --notification-font-size: var(--font-size--sm);
  font-family: var(--font-family--primary);
  margin: 0;
  padding: 0;
  a {
    text-decoration: none;
    color: var(--c-link);
    cursor: pointer;
    &:hover {
      color: var(--c-link-hover);
    }
  }
}

#viewport {
  position: relative;
}
.bodylock {
  overflow: hidden;
}
@include for-desktop {
  .sidebar {
    &__microcart {
      --sidebar-aside-width: 700px;
    }
  }
}
</style>
