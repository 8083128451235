import { VueStorefrontModule } from '@vue-storefront/core/lib/module'
import { registerModule } from '@vue-storefront/core/lib/modules'

import { CatalogModule } from '@vue-storefront/core/modules/catalog'
import { CatalogNextModule } from '@vue-storefront/core/modules/catalog-next'
import { CartModule } from '@vue-storefront/core/modules/cart'
import { CheckoutModule } from '@vue-storefront/core/modules/checkout'
import { OrderModule } from '@vue-storefront/core/modules/order'
import { NotificationModule } from '@vue-storefront/core/modules/notification'
import { UrlModule } from '@vue-storefront/core/modules/url'
import { BreadcrumbsModule } from '@vue-storefront/core/modules/breadcrumbs'
import { UserModule } from '@vue-storefront/core/modules/user'
import { CmsModule } from '@vue-storefront/core/modules/cms'
import { NewsletterModule } from '@vue-storefront/core/modules/newsletter'

import { CatalogExtensionModule } from 'src/modules/catalog-extension';
import { PaymentBackendMethodsModule } from 'src/modules/payment-backend-methods'
import { PaymentCashOnDeliveryModule } from 'src/modules/payment-cash-on-delivery'
import { GoogleTagManagerModule } from 'src/modules/google-tag-manager';
import { Logtrade } from 'src/modules/logtrade'
import { Collector } from 'src/modules/collector'
import { StampedIo } from 'src/modules/stamped-io'
import { CartModuleExtension } from 'src/modules/cart-extension'
import { UpsellFreeGifts } from 'src/modules/upsell-free-gifts'
import { ZendeskModule } from 'src/modules/zendesk'
import { AdyenModule } from 'src/modules/adyen'

import { Klaviyo } from 'src/modules/klaviyo'
import { Trustbadge } from 'src/modules/trustbadge'
import { KlarnaCheckout } from 'src/modules/payment-klarna'
import { Feedback } from 'src/modules/feedback'
import { FaqModule } from 'src/modules/faqs'
import { PaymentPaypalModule } from 'src/modules/paypal'

// TODO:distributed across proper pages BEFORE 1.11
export function registerClientModules () {
  registerModule(UrlModule)
  registerModule(CatalogModule)
  registerModule(CatalogExtensionModule)
  registerModule(CheckoutModule) // To Checkout
  registerModule(AdyenModule)
  registerModule(CartModule)
  registerModule(PaymentBackendMethodsModule)
  registerModule(PaymentCashOnDeliveryModule)
  // registerModule(GoogleTagManagerModule) //Register while clicking on accept cookie policy button
  registerModule(NotificationModule)
  registerModule(UserModule) // Trigger on user icon click
  registerModule(CatalogNextModule)
  registerModule(BreadcrumbsModule)
  registerModule(CmsModule)
  registerModule(NewsletterModule)
  registerModule(StampedIo)
  registerModule(UpsellFreeGifts)
  registerModule(Logtrade)
  registerModule(OrderModule)
  registerModule(CartModuleExtension)
  registerModule(ZendeskModule)
  // registerModule(Klaviyo) //Register while clicking on accept cookie policy button
  registerModule(Trustbadge)
  registerModule(Collector)
  registerModule(Feedback)
  registerModule(FaqModule)
  registerModule(PaymentPaypalModule)
}

// Deprecated API, will be removed in 2.0
export const registerModules: VueStorefrontModule[] = [
  KlarnaCheckout
]
