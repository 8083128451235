import { calculateBundleOptionsPrice } from 'theme/helpers/price/bundleProducts';
import { formatPrice } from 'theme/helpers';
import { getCustomOptionPriceDelta, getCustomOptionValues } from '@vue-storefront/core/modules/catalog/helpers/customOption';

function calculateCustomOptionsPriceDelta (product, customOptions) {
  const priceDelta = getCustomOptionPriceDelta(
    getCustomOptionValues(Object.values(customOptions), product.custom_options),
    product
  )

  return priceDelta.priceInclTax
}

export function calculatePriceWithCapybaraLogic (product, customOptions, number) {
  const originalPriceInclTax = product.original_price_incl_tax || product.originalPriceInclTax || 0
  const priceInclTax = product.price_incl_tax || product.priceInclTax || 0
  const originalSpecialPrice = product.original_special_price || product.originalSpecialPrice || 0
  const specialPrice = product.special_price || product.specialPrice || 0
  const isSpecialPrice = specialPrice || originalSpecialPrice
  const priceDelta = calculateCustomOptionsPriceDelta(product, customOptions)

  const special = specialPrice * product.qty || (priceInclTax + priceDelta) * product.qty || priceInclTax
  const original = (originalPriceInclTax + priceDelta) * product.qty || originalPriceInclTax
  const regular = calculateBundleOptionsPrice(product) || (priceInclTax + priceDelta) * product.qty || priceInclTax

  if (number) {
    let result = {
      regular: isSpecialPrice ? original : regular,
      special: isSpecialPrice ? special : null
    }
    result.regular = parseFloat(result.regular)
    result.special = result.special ? parseFloat(result.special) : null
    return result
  }
  let result = {
    regular: isSpecialPrice ? formatPrice(original) : formatPrice(regular),
    special: isSpecialPrice ? formatPrice(special) : '',
    final: formatPrice(product.final_price)
  }
  return result
}
