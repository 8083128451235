import { getCheaperPrice, scalePriceByQuantity } from 'theme/helpers/price/utils';

export function calculateTierPrice (product, overrideQuantity = null) {
  const quantity = overrideQuantity || product.qty
  if (!product.tier_prices || product.tier_prices.length === 0 || !product.tier_value) return null
  const matchingTier = product.tier_prices
    .filter(tier => tier.qty <= quantity)
    .sort((a, b) => b.qty - a.qty)[0]
  return (matchingTier && matchingTier.value) ? matchingTier.value : null;
}

export function usesTierPrices (product) {
  return product.tier_prices && product.tier_prices.length !== 0 && product.tier_value
}

export function calculateTieredProductPrice (product, quantity) {
  const minimalTier = [...product.tier_prices].find(tier => tier.qty === 1)
  const minimalTierPrice = minimalTier && minimalTier.value
  const baseTierPrice = minimalTierPrice || product.regular_price || product.price
  const tierPrice = calculateTierPrice(product, quantity)
  const optimalPrice = scalePriceByQuantity(getCheaperPrice(tierPrice, product.special_price), quantity)
  const regularPrice = scalePriceByQuantity(baseTierPrice, quantity)
  return {
    regular: regularPrice,
    special: optimalPrice,
    final: optimalPrice
  }
}
